import React from "react";
import "./stylesGameDev.scss";
import useStore from "../stores/store";

function GameDev() {
  const { theme } = useStore();

  return <></>;
}

export default GameDev;
