// components/AvatarDialog/dialogueData.js
export const dialogues = [
  [
    "Hi there! I'm David.",
    "I support entrepreneurs with marketing and creative development.",
  ],
  [
    "You're looking at an active, personal project of mine.",
    "The procedural map in the background will probably become a game soon...",
  ],
  [
    "For now, go ahead and click 'menu' in the top right corner to explore the rest of the site.",
    "Thanks for stopping by!",
  ],
];
