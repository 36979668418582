import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Grid } from "mauerwerk";
import ErrorBoundary from "../../utils/ErrorBoundary.jsx";
import Loader from "../Loader/Loader.jsx";
import { useViewport } from "../../context/ViewportContext";
import { breakpoints } from "../../styles/breakpoints";
import { typography } from "../../styles/abstracts/typography.js";
import { Book, Lock } from "lucide-react";
import useStore from "../../stores/store.js";

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-row: 2;
  width: 100%;
  height: auto;

  ${({ $maximized, $viewport, theme }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
    ${
      $maximized
        ? `
        grid-column: 1 / -1; 
        grid-row: 1 / -1;
        padding-top: 3rem;
        z-index: 1500;
        background-color: ${theme.tertiaryColor};
        justify-content: flex-start;
      `
        : `
        grid-column: 1;
      `
    }
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
    ${
      $maximized
        ? `
        grid-column: 1 / -1; 
        grid-row: 1 / -1;
        padding-top: 3rem;
        z-index: 1500;
        background-color: ${theme.tertiaryColor};
        justify-content: flex-start;
      `
        : `
        grid-column: 1;
      `
    }
  `;
    } else if (width <= breakpoints.mobileL) {
      return `
          ${
            $maximized
              ? `
                grid-column: 1 / -1; 
                grid-row: 1 / -1;
                padding-top: 3rem;
                z-index: 1500;
                background-color: ${theme.tertiaryColor};
                justify-content: flex-start;
              `
              : `
                grid-column: 1;
              `
          }
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        ${
          $maximized
            ? `
              max-width: 100%;
              max-height: 600px;
            `
            : ``
        }
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        ${
          $maximized
            ? `
              max-width: 500px;
              max-height: 600px;
              grid-column: ${isLandscape ? `2` : `1`};
            `
            : `${isLandscape ? `grid-column: 2;` : `grid-column: 1`}`
        }
      `;
    } else if (width <= breakpoints.tabletL) {
      return `
        ${
          $maximized
            ? `
              max-width: min(500px, 75%);
              max-height: min(500px, 100%);
              grid-column: ${isLandscape ? `2` : `1`};
              
            `
            : `${isLandscape ? `grid-column: 2;` : `grid-column: 1`}`
        }
      `;
    } else if (width <= breakpoints.laptopS) {
      return `
    ${
      $maximized
        ? `
          max-width: 450px;
          max-height: min(450px, 100%);
          grid-column: 1;
          justify-content: flex-end;
        `
        : `grid-column: 1;`
    }
  `;
    } else if (width <= breakpoints.laptopM) {
      return `
        ${
          $maximized
            ? `
            max-width: 450px;
            max-height: min(550px, 100%);
            grid-column: 1;
          `
            : `grid-column: 1;`
        }
        `;
    } else if (width <= breakpoints.laptopL) {
      return `
        ${
          $maximized
            ? `
            max-width: 450px;
            max-height: min(600px, 100%);
            grid-column: 1;
          `
            : `grid-column: 1;`
        }
      `;
    } else if (width <= breakpoints.desktop) {
      return `
        ${
          $maximized
            ? `
              max-width: min(500px, 75%);
              max-height: min(500px, 100%);
              grid-column: ${isLandscape ? `1` : `1`};
              
            `
            : `${isLandscape ? `grid-column: 1;` : `grid-column: 1/-1`}`
        }
      `;
    } else if (width <= breakpoints.desktopL) {
      return `
        ${
          $maximized
            ? `
              max-width: min(600px, 75%);
              max-height: min(700px, 100%);
              grid-column: ${isLandscape ? `1` : `1`};
              
            `
            : `${isLandscape ? `grid-column: 1;` : `grid-column: 1/-1`}`
        }
      `;
    }
  }}
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.textColorLight};
  text-align: center;
  padding: 20px;
`;

const GridContainer = styled.div`
  width: 100%;
  height: auto;
  align-content: center;
  position: relative;
`;

const Project = styled.div`
  position: relative;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  align-content: center;
  background-color: ${({ theme }) => theme.tertiaryColor};
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  ${({ $maximized, $viewport }) => {
    const { width, isLandscape } = $viewport;
    if ($maximized) {
      if (width <= breakpoints.mobileS) {
        return `
          width: 100%;
          height: 100%;
          border-radius: 0;
          margin: 0;
        `;
      } else if (width <= breakpoints.mobileM) {
        return `
          width: 101%;
          height: 101%;
          border-radius: 0;
          margin: 0;
        `;
      } else if (width <= breakpoints.mobileL) {
        return `
          width: 100%;
          height: 100%;
          border-radius: 0;
          margin: 0;
        `;
      } else if (width <= breakpoints.tabletM) {
        return `
          width: 90%;
          height: 90%;
          border-radius: 10px;
          margin: 1rem auto;
        `;
      } else {
        return `
          width: 95%;
          height: min(450px, 80%);
          border-radius: 10px;
          margin: 0 auto;
          top: 15%;
        `;
      }
    }
  }}

  &:hover {
    ${({ $maximized }) =>
      !$maximized &&
      `
      transform: translateY(-5px) scale(1.02);
      background-color: ${({ theme }) => theme.secondaryColor};
    `}
  }
`;

const ProjectPreview = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.secondaryColor};
  position: relative;
  transition: height 0.3s ease;

  ${({ $maximized, $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        height: ${$maximized ? "30vh" : isLandscape ? "130px" : "150px"};
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        height: ${$maximized ? "35vh" : isLandscape ? "130px" : "125px"};
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        height: ${$maximized ? "40vh" : isLandscape ? "130px" : "125px"};
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
      height: ${$maximized ? "50%" : isLandscape ? "130px" : "150px"};
    `;
    } else if (width <= breakpoints.tabletM) {
      return `
      height: ${$maximized ? "50%" : isLandscape ? "90px" : "150px"};
    `;
    } else if (width <= breakpoints.tabletL) {
      return `
      height: ${$maximized ? "50%" : isLandscape ? "125px" : "150px"};
    `;
    } else if (width <= breakpoints.laptopS) {
      return `
      height: ${$maximized ? "45%" : isLandscape ? "100px" : "150px"};
    `;
    } else if (width <= breakpoints.laptopM) {
      return `
      height: ${$maximized ? "40%" : isLandscape ? "125px" : "175px"};
    `;
    } else if (width <= breakpoints.laptopL) {
      return `
      height: ${$maximized ? "40%" : isLandscape ? "160px" : "175px"};
    `;
    } else if (width <= breakpoints.desktop) {
      return `
      height: ${$maximized ? "50%" : isLandscape ? "175px" : "175px"};
    `;
    }
    // For larger screens
    else
      return `
        height: ${$maximized ? "50%" : isLandscape ? "200px" : "275px"};
      `;
  }}
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: ${({ $maximized }) => ($maximized ? "none" : "grayscale(100%)")};
  opacity: ${({ $maximized }) => ($maximized ? "1" : "0.75")};
  transition: all 0.3s ease;

  ${Project}:hover & {
    filter: none;
    mix-blend-mode: normal;
    opacity: 1;
  }
`;

const ProjectMeta = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: ${typography.fontFamilyMono};
  font-size: 11px;
  font-weight: 500;

  ${({ $maximized, $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        font-size: 24px;
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
    `;
    } else if (width <= breakpoints.tabletM) {
      return `
        font-size: ${$maximized ? "1.25rem" : isLandscape ? "0.5rem" : "0.75rem"};
    `;
    } else if (width <= breakpoints.tabletL) {
      return `
        ${
          $maximized
            ? `
              font-size: ${isLandscape ? `1rem;` : `0.75rem`};      
            `
            : `
              font-size: ${isLandscape ? `0.5rem;` : `0.5rem`};   
            `
        }
    `;
    } else if (width <= breakpoints.laptopS) {
      return `
    `;
    } else if (width <= breakpoints.laptopM) {
      return `
    `;
    } else if (width <= breakpoints.laptopL) {
      return `
    `;
    }
    // For larger screens
    else
      return `
  `;
  }}
`;

const MetaItem = styled.span`
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 0.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.textColorLight};

  &:hover {
    text-decoration: underline;
  }

  ${({ $maximized, $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        ${
          $maximized
            ? `
              height: ${isLandscape ? `2rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.75rem;` : `0.5rem 0.75rem`};
              font-size: ${isLandscape ? `0.75rem;` : `0.75rem`};      
            `
            : `
              height: ${isLandscape ? `1rem;` : `0.75rem`};
              padding: ${isLandscape ? `0.125rem 0.25rem;` : `0.125rem 0.5rem`};
              font-size: ${isLandscape ? `0.5rem;` : `0.45rem`};   
            `
        }
    `;
    } else if (width <= breakpoints.mobileM) {
      return `
        ${
          $maximized
            ? `
              height: ${isLandscape ? `2rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.75rem;` : `0.5rem 0.75rem`};
              font-size: ${isLandscape ? `0.75rem;` : `0.75rem`};      
            `
            : `
              height: ${isLandscape ? `1rem;` : `0.75rem`};
              padding: ${isLandscape ? `0.125rem 0.25rem;` : `0.125rem 0.5rem`};
              font-size: ${isLandscape ? `0.5rem;` : `0.45rem`};   
            `
        }
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        ${
          $maximized
            ? `
              height: ${isLandscape ? `2rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.75rem;` : `0.5rem 0.75rem`};
              font-size: ${isLandscape ? `0.75rem;` : `0.75rem`};      
            `
            : `
              height: ${isLandscape ? `1rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.25rem;` : `0.25rem 0.5rem 0.125rem 0.5rem`};
              font-size: ${isLandscape ? `0.5rem;` : `0.5rem`};   
            `
        }
    `;
    } else if (width <= breakpoints.tabletS) {
      return `
        ${
          $maximized
            ? `
              height: ${isLandscape ? `2rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.75rem;` : `0.5rem 0.75rem`};
              font-size: ${isLandscape ? `0.75rem;` : `0.75rem`};      
            `
            : `
              height: ${isLandscape ? `1rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.25rem;` : `0.25rem 0.5rem`};
              font-size: ${isLandscape ? `0.5rem;` : `0.5rem`};   
            `
        }
    `;
    } else if (width <= breakpoints.tabletM) {
      return `
        ${
          $maximized
            ? `
              height: ${isLandscape ? `2rem` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.75rem` : `0.5rem 0.75rem`};
              font-size: ${isLandscape ? `0.75rem` : `0.75rem`};      
            `
            : `
              height: ${isLandscape ? `1rem` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.25rem` : `0.25rem 0.5rem`};
              font-size: ${isLandscape ? `0.5rem;` : `0.5rem`};   
            `
        }
    `;
    } else if (width <= breakpoints.tabletL) {
      return `
        ${
          $maximized
            ? `
              height: ${isLandscape ? `1.5rem` : `1rem`};
              padding: ${isLandscape ? `0.25rem 0.75rem` : `0.25rem 0.5rem`};
              font-size: ${isLandscape ? `0.75rem` : `0.75rem`};      
            `
            : `
              height: ${isLandscape ? `1rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.25rem;` : `0.25rem 0.5rem`};
              font-size: ${isLandscape ? `0.5rem;` : `0.5rem`};   
            `
        }
    `;
    } else if (width <= breakpoints.laptopS) {
      return `
        ${
          $maximized
            ? `
              height: ${isLandscape ? `1.5rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.75rem;` : `0.5rem 0.75rem`};
              font-size: ${isLandscape ? `0.75rem;` : `0.75rem`};       
            `
            : `
              height: ${isLandscape ? `1rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.25rem;` : `0.5rem 0.75rem`};
              font-size: ${isLandscape ? `0.5rem;` : `0.5rem`};   
            `
        }
    `;
    } else if (width <= breakpoints.laptopM) {
      return `
        ${
          $maximized
            ? `
              height: ${isLandscape ? `2rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.75rem;` : `0.5rem 0.75rem`};
              font-size: ${isLandscape ? `0.9rem;` : `0.75rem`};      
            `
            : `
              height: ${isLandscape ? `1rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.25rem;` : `0.5rem 0.75rem`};
              font-size: ${isLandscape ? `0.5rem;` : `0.5rem`};   
            `
        }
    `;
    } else if (width <= breakpoints.laptopL) {
      return `
              ${
                $maximized
                  ? `
              height: ${isLandscape ? `2rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.75rem;` : `0.5rem 0.75rem`};
              font-size: ${isLandscape ? `0.9rem;` : `0.75rem`};      
            `
                  : `
              height: ${isLandscape ? `1rem;` : `1rem`};
              padding: ${isLandscape ? `0.125rem 0.25rem;` : `0.5rem 0.75rem`};
              font-size: ${isLandscape ? `0.5rem;` : `0.5rem`};   
            `
              }
    `;
    }
    // For larger screens
    else
      return `
  `;
  }}
`;

const IssueItem = styled(MetaItem)`
  background-color: ${({ theme }) => theme.tertiaryColor};
  border-radius: 4px 0 0 0;
`;

const PageItem = styled(MetaItem)`
  background-color: ${({ theme }) => theme.secondaryColor};
`;

const ProjectContent = styled.div`
  display: grid;
  gap: 14px;

  ${({ $maximized, $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        display: flex;
        flex-direction: column;

        ${
          $maximized
            ? `
            grid-column: 1/-1; 
            z-index: 1500;
            gap: 0.5rem;
            padding: ${isLandscape ? "1rem" : "1rem"};
            `
            : `
              grid-column: 1;
              padding: 0.5rem 0.5rem 0.5rem 0.5rem};
            `
        }
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        display: flex;
        flex-direction: column;

        ${
          $maximized
            ? `
            grid-column: 1/-1; 
            z-index: 1500;
            gap: 0.5rem;
            padding: ${isLandscape ? "1rem" : "1rem"};
            `
            : `
              grid-column: 1;
              padding: 0.5rem 0.5rem 0.5rem 0.5rem};
            `
        }
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        ${
          $maximized
            ? `
            grid-column: 1/-1; 
            z-index: 1500;
            gap: 0.5rem;
            padding: ${isLandscape ? "1rem" : "1.5rem"};
            `
            : `
              grid-column: 1;
              padding: 0.75rem 0.75rem 0.75rem 0.75rem};
            `
        }
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        ${
          $maximized
            ? `
            grid-column: 1/-1; 
            z-index: 1500;
            gap: 0.5rem;
            padding: ${isLandscape ? "1rem" : "1.5rem"};
            `
            : `
              grid-column: 1;
              padding: 0.75rem 0.75rem 0.75rem 0.5rem};
            `
        }
      `;
    } else if (width <= breakpoints.tabletM) {
      return `
        ${
          $maximized
            ? `
            z-index: 1500;
            gap: 0.5rem;
            padding: ${isLandscape ? "1rem" : "1.5rem 2rem 2rem 2rem"};
            `
            : `
              padding: 0.75rem 0.75rem 0.75rem 0.5rem};
            `
        }
    `;
    } else if (width <= breakpoints.tabletL) {
      return `
        ${
          $maximized
            ? `
            z-index: 1500;
            gap: 0;
            padding: ${isLandscape ? "1rem 1.5rem" : "1.5rem 2rem 2rem 2rem"};
            `
            : `
              padding: 0.75rem 0.5rem 0.75rem 1rem};
            `
        }
    `;
    } else if (width <= breakpoints.laptopS) {
      return `
        ${
          $maximized
            ? `
              z-index: 1500;
              gap: 0;
              height: 100%;
              padding: ${isLandscape ? "1rem 1.5rem" : "1.5rem 2rem 2rem 2rem"};
              `
            : `
                padding: 0.75rem 0.5rem 0.75rem 1rem};
              `
        }
    `;
    } else if (width <= breakpoints.laptopM) {
      return `
        ${
          $maximized
            ? `
              z-index: 1500;
              gap: 0.25rem;
              padding: ${isLandscape ? "1rem 1.5rem" : "1.5rem 2rem 2rem 2rem"};
              `
            : `
                padding: 0.75rem 0.5rem 0.75rem 1rem};
              `
        }
    `;
    } else if (width <= breakpoints.laptopL) {
      return `
        ${
          $maximized
            ? `
              z-index: 1500;
              gap: 0.25rem;
              padding: ${isLandscape ? "1rem 1.5rem" : "1.5rem 2rem 2rem 2rem"};
              `
            : `
                padding: 1rem 0.5rem 1rem 1rem};
              `
        }
    `;
    } else if (width <= breakpoints.desktop) {
      return `
        ${
          $maximized
            ? `
              z-index: 1500;
              gap: 0.25rem;
              padding: ${isLandscape ? "1rem 1.5rem" : "1.5rem 2rem 2rem 2rem"};
              `
            : `
                padding: 1rem 0.5rem 1rem 1rem};
              `
        }
    `;
    } else if (width <= breakpoints.desktopL) {
      return `
        ${
          $maximized
            ? `
              z-index: 1500;
              gap: 0.25rem;
              padding: ${isLandscape ? "1rem 1.5rem" : "1.5rem 2rem 2rem 2rem"};
              `
            : `
                padding: 1rem 0.5rem 1rem 1rem};
              `
        }
    `;
    } else
      return `
  `;
  }}
`;

const ProjectTitle = styled.h2`
  color: ${({ theme }) => theme.textColorLight};
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: ${({ $maximized }) => ($maximized ? "block" : "-webkit-box")};
  -webkit-line-clamp: ${({ $maximized }) => ($maximized ? "none" : "2")};
  -webkit-box-orient: vertical;

  ${({ $maximized, $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        font-size: ${$maximized ? "2rem" : isLandscape ? "0.75rem" : "0.75rem"};
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        font-size: ${$maximized ? "2rem" : isLandscape ? "0.75rem" : "0.9rem"};
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        font-size: ${$maximized ? "2rem" : isLandscape ? "0.75rem" : "1rem"};
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        font-size: ${$maximized ? "1.5rem" : isLandscape ? "0.9rem" : "1.25rem"};
    `;
    } else if (width <= breakpoints.tabletM) {
      return `
        font-size: ${$maximized ? "1.5rem" : isLandscape ? "0.9rem" : "1rem"};
    `;
    } else if (width <= breakpoints.tabletL) {
      return `
        ${
          $maximized
            ? `
              font-size: ${isLandscape ? `1.25rem;` : `1.25rem`};
              `
            : `
              font-size: ${isLandscape ? `0.9rem;` : `1rem`};
              `
        }
    `;
    } else if (width <= breakpoints.laptopS) {
      return `
        ${
          $maximized
            ? `
              font-size: ${isLandscape ? `1.75rem;` : `1.25rem`};
              `
            : `
              font-size: ${isLandscape ? `0.9rem;` : `0.9rem`};
              `
        }
    `;
    } else if (width <= breakpoints.laptopM) {
      return `
        font-size: ${$maximized ? "1.75rem" : isLandscape ? "1.25rem" : "0.9rem"};
    `;
    } else if (width <= breakpoints.laptopL) {
      return `
      font-size: ${$maximized ? "1.75rem" : isLandscape ? "1.25rem" : "0.9rem"};
    `;
    } else if (width <= breakpoints.desktop) {
      return `
      font-size: ${$maximized ? "1.75rem" : isLandscape ? "1.25rem" : "0.9rem"};
    `;
    } else if (width <= breakpoints.desktopL) {
      return `
      font-size: ${$maximized ? "2rem" : isLandscape ? "1.5rem" : "0.9rem"};
    `;
    } else
      return `
      font-size: 22px;
  `;
  }}
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0 4px;
  position: relative;
  overflow: ${({ $maximized }) => ($maximized ? "visible" : "hidden")};

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    background: ${({ $maximized, theme }) =>
      $maximized
        ? "none"
        : `linear-gradient(90deg, transparent, ${theme.tertiaryColor})`};
    pointer-events: none;
  }
`;

const Tag = styled.a`
  border: 1px solid ${({ theme }) => `${theme.textColorLight}33`};
  border-radius: 100px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  padding: 0 14px;
  height: 32px;
  color: ${({ theme }) => theme.textColorLight};
  text-decoration: none;
  transition: all 0.15s ease;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme }) => `${theme.textColorLight}33`};
    border-color: transparent;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border: none;
  background: ${({ theme }) => theme.tertiaryColor};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.secondaryColor};
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: ${({ theme }) => theme.textColorLight};
    transform-origin: center;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ProjectDescription = styled.p`
  color: ${({ theme }) => theme.textColorLight};
  font-size: 16px;
  line-height: 1.6;
  margin: 0;

  ${({ $maximized, $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 0.9rem; line-height: 1.5rem;`) : `font-size: 0.95rem`};
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 1rem; line-height: 1.5rem;`) : `font-size: 0.95rem`};
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 1rem; line-height: 1.5rem;`) : `font-size: 0.95rem`};
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 0.75rem; line-height: 1.25rem;`) : `font-size: 0.95rem`};
    `;
    } else if (width <= breakpoints.tabletM) {
      return `
        ${$maximized ? (isLandscape ? `font-size: 0.75rem` : `font-size: 0.75rem; line-height: 1.25rem;`) : `font-size: 0.75rem`};
    `;
    } else if (width <= breakpoints.tabletL) {
      return `
        ${
          $maximized
            ? `
              font-size: ${isLandscape ? `0.65rem;` : `1rem`};
              `
            : `
              font-size: ${isLandscape ? `0.9rem;` : `0.9rem`};
              `
        }
    `;
    } else if (width <= breakpoints.laptopS) {
      return `
      ${$maximized ? (isLandscape ? `font-size: 0.85rem` : `font-size: 1rem; line-height: 1.5rem;`) : `font-size: 0.75rem`};
    `;
    } else if (width <= breakpoints.laptopM) {
      return `
      ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 1rem; line-height: 1.8rem;`) : `font-size: 0.75rem`};
    `;
    } else if (width <= breakpoints.laptopL) {
      return `
      ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 1rem; line-height: 1.8rem;`) : `font-size: 0.75rem`};
    `;
    } else if (width <= breakpoints.desktop) {
      return `
      ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 1rem; line-height: 1.8rem;`) : `font-size: 0.75rem`};
    `;
    } else if (width <= breakpoints.desktopL) {
      return `
      ${$maximized ? (isLandscape ? `font-size: 1.25rem` : `font-size: 1rem; line-height: 1.8rem;`) : `font-size: 0.75rem`};
    `;
    } else
      return `
  `;
  }}
`;

const ProjectActions = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 20px;
  align-self: flex-end;

  ${({ $maximized, $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
    `;
    } else if (width <= breakpoints.tabletM) {
      return `
    `;
    } else if (width <= breakpoints.tabletL) {
      return `
        margin-top: 1rem;
    `;
    } else if (width <= breakpoints.laptopS) {
      return `
    `;
    } else if (width <= breakpoints.laptopM) {
      return `
    `;
    } else if (width <= breakpoints.laptopL) {
      return `
        margin-top: 0.75rem;
    `;
    } else if (width <= breakpoints.desktop) {
      return `
        margin-top: 1rem;
    `;
    } else
      return `
  `;
  }}
`;

const ActionButton = styled.button`
  background: ${({ theme, $disabled }) =>
    $disabled ? theme.disabledColor : theme.secondaryColor};
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.disabledTextColor : theme.textColorLight};
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  max-height: 2.5rem;

  &:hover {
    background: ${({ theme, $disabled }) =>
      $disabled ? theme.disabledColor : theme.primaryColor};
  }

  ${({ $maximized, $viewport }) => {
    const { width, isLandscape } = $viewport;

    if (width <= breakpoints.mobileS) {
      return `
        ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 0.9rem; line-height: 1.5rem;`) : `font-size: 0.95rem`};
      `;
    } else if (width <= breakpoints.mobileM) {
      return `
        ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 1rem; line-height: 1.5rem;`) : `font-size: 0.95rem`};
      `;
    } else if (width <= breakpoints.mobileL) {
      return `
        ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 1rem; line-height: 1.5rem;`) : `font-size: 0.95rem`};
      `;
    } else if (width <= breakpoints.tabletS) {
      return `
        ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 0.75rem; line-height: 1.25rem;`) : `font-size: 0.95rem`};
    `;
    } else if (width <= breakpoints.tabletM) {
      return `
        ${$maximized ? (isLandscape ? `font-size: 0.95rem` : `font-size: 0.75rem; line-height: 1.25rem;`) : `font-size: 0.75rem`};
    `;
    } else if (width <= breakpoints.tabletL) {
      return `
        ${
          $maximized
            ? `
              font-size: ${isLandscape ? `0.75rem;` : `1rem`};
              `
            : `
              font-size: ${isLandscape ? `0.9rem;` : `0.9rem`};
              `
        }
    `;
    } else if (width <= breakpoints.laptopS) {
      return `
      ${$maximized ? (isLandscape ? `font-size: 0.75rem` : `font-size: 1rem; line-height: 1.5rem;`) : `font-size: 0.75rem`};
    `;
    } else if (width <= breakpoints.laptopM) {
      return `
    `;
    } else if (width <= breakpoints.laptopL) {
      return `
    `;
    } else
      return `
  `;
  }}
`;

const ProjectList = ({
  projects,
  isLoading,
  error,
  itemsPerPage,
  selectedTab,
}) => {
  const { theme } = useStore();
  const viewport = useViewport();
  const [columns, setColumns] = useState(4);
  const [cellHeight, setCellHeight] = useState(350);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [maximizedItem, setMaximizedItem] = useState(null);

  const gridHeight = 100;

  useEffect(() => {
    const handleResize = () => {
      if (viewport.width <= breakpoints.mobileS) {
        setColumns(1);
        setCellHeight(viewport.isLandscape ? 80 : 175);
      } else if (viewport.width <= breakpoints.mobileM) {
        setColumns(1);
        setCellHeight(viewport.isLandscape ? 100 : 150);
      } else if (viewport.width <= breakpoints.mobileL) {
        setColumns(1);
        setCellHeight(viewport.isLandscape ? 100 : 165);
      } else if (viewport.width <= breakpoints.tabletS) {
        setColumns(1);
        setCellHeight(viewport.isLandscape ? 110 : 200);
      } else if (viewport.width <= breakpoints.tabletM) {
        setColumns(viewport.isLandscape ? 1 : 2);
        setCellHeight(viewport.isLandscape ? 125 : 200);
      } else if (viewport.width <= breakpoints.tabletL) {
        setColumns(viewport.isLandscape ? 2 : 2);
        setCellHeight(viewport.isLandscape ? 175 : 200);
      } else if (viewport.width <= breakpoints.laptopS) {
        setColumns(viewport.isLandscape ? 2 : 2);
        setCellHeight(viewport.isLandscape ? 150 : 200);
      } else if (viewport.width <= breakpoints.laptopM) {
        setColumns(viewport.isLandscape ? 2 : 2);
        setCellHeight(viewport.isLandscape ? 175 : 225);
      } else if (viewport.width <= breakpoints.laptopL) {
        setColumns(viewport.isLandscape ? 2 : 2);
        setCellHeight(viewport.isLandscape ? 215 : 225);
      } else if (viewport.width <= breakpoints.desktop) {
        setColumns(3);
        setCellHeight(viewport.isLandscape ? 235 : 250);
      } else {
        setColumns(3);
        setCellHeight(viewport.isLandscape ? 275 : 250);
      }
      setForceUpdate(true);
    };

    handleResize();
  }, [viewport]);

  const handleItemClick = useCallback(
    (item, maximized, toggle) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      toggle();
      setMaximizedItem(maximized ? null : item);
      if (!maximized) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    },
    []
  );

  if (isLoading) {
    return <Loader isLoading={true} text="Loading items..." />;
  }

  if (error) {
    return <ErrorMessage theme={theme}>{error}</ErrorMessage>;
  }

  if (!projects || projects.length === 0) {
    return (
      <ErrorMessage theme={theme}>
        No items available for the '{selectedTab}' category.
      </ErrorMessage>
    );
  }

  const getItemImage = (item) => {
    if (item.itemType === "article") {
      return item.featured_image || item.thumbnail_img;
    }
    return item.thumbnail_img;
  };

  const getItemTitle = (item) => {
    if (item.itemType === "article") {
      return item.name; // For articles, the title is stored in the 'name' field
    }
    return item.name; // For projects, we keep using the 'name' field
  };

  const getItemTags = (item) => {
    return item.tags || [];
  };

  const getItemDescription = (item) => {
    if (item.itemType === "caseStudy") {
      return item.excerpt || item.mission || "No description available.";
    }
    return item.description || "No description available.";
  };

  const getCategoryAbbreviation = (category) => {
    return category && category.name ? category.name.slice(0, 2) : "XX";
  };

  const getPageNumber = (id) => {
    return id.toString().padStart(2, "0");
  };

  const getActionButtonText = (item) => {
    if (!item.url) {
      return "Coming Soon";
    }

    switch (item.itemType) {
      case "article":
        return "Read Article";
      case "project":
        if (item.category && item.category.name === "Photography") {
          return "View Gallery";
        }
        return item.status === "current" ? "View Progress" : "View Project";
      case "caseStudy":
        return "View Case Study";
      default:
        return "Learn More";
    }
  };

  return (
    <ListWrapper
      $viewport={viewport}
      $maximized={!!maximizedItem}
      theme={theme}
      className="PROJECT WRAPPER"
    >
      <GridContainer gridHeight={gridHeight}>
        {forceUpdate && (
          <Grid
            className="grid"
            data={projects}
            keys={(item) => `${item.id}-${item.itemType}`}
            columns={columns}
            heights={() => cellHeight}
            lockScroll={true}
            margin={20}
          >
            {(item, maximized, toggle, index) => (
              <Project
                onClick={handleItemClick(item, item === maximizedItem, toggle)}
                theme={theme}
                $maximized={item === maximizedItem}
                $viewport={viewport}
                className="PROJECT"
              >
                {item === maximizedItem && (
                  <CloseButton
                    onClick={(e) => {
                      e.stopPropagation();
                      toggle();
                      setMaximizedItem(null);
                      document.body.style.overflow = "";
                    }}
                    theme={theme}
                    className="closeBUTTON"
                  />
                )}
                <ProjectPreview
                  theme={theme}
                  $maximized={maximized}
                  $viewport={viewport}
                >
                  <ProjectImage
                    src={getItemImage(item)}
                    alt={getItemTitle(item)}
                    $maximized={maximized}
                  />
                  <ProjectMeta
                    $viewport={viewport}
                    $maximized={maximized}
                    theme={theme}
                    className="MetaItems"
                  >
                    <IssueItem
                      theme={theme}
                      $viewport={viewport}
                      $maximized={maximized}
                      href="#"
                    >
                      {getCategoryAbbreviation(item.category)}
                    </IssueItem>
                    <PageItem
                      theme={theme}
                      $viewport={viewport}
                      $maximized={maximized}
                      href="#"
                    >
                      {getPageNumber(item.id)}
                    </PageItem>
                  </ProjectMeta>
                </ProjectPreview>
                <ProjectContent
                  theme={theme}
                  $viewport={viewport}
                  $maximized={maximized}
                >
                  {!maximized && (
                    <ProjectTitle
                      theme={theme}
                      $viewport={viewport}
                      $maximized={maximized}
                    >
                      {getItemTitle(item)}
                    </ProjectTitle>
                  )}
                  {maximized && (
                    <>
                      <DescriptionWrapper>
                        <ProjectTitle
                          theme={theme}
                          $viewport={viewport}
                          $maximized={maximized}
                        >
                          {getItemTitle(item)}
                        </ProjectTitle>
                        <ProjectDescription
                          theme={theme}
                          $viewport={viewport}
                          $maximized={maximized}
                        >
                          {getItemDescription(item)}
                        </ProjectDescription>
                      </DescriptionWrapper>
                      <ProjectActions
                        $viewport={viewport}
                        $maximized={maximized}
                      >
                        <ActionButton
                          theme={theme}
                          $viewport={viewport}
                          $maximized={maximized}
                          $disabled={!item.url}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item.url) {
                              window.open(item.url, "_blank");
                            }
                          }}
                        >
                          {item.url ? <Book size={18} /> : <Lock size={18} />}
                          {getActionButtonText(item)}
                        </ActionButton>
                      </ProjectActions>
                    </>
                  )}
                </ProjectContent>
              </Project>
            )}
          </Grid>
        )}
      </GridContainer>
    </ListWrapper>
  );
};

export default ProjectList;
