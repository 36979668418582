/**
 * store.js
 *
 * This file sets up the central state management for the application using Zustand.
 * It combines multiple state slices and configures middleware for development and persistence.
 */

import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { QueryClient } from "react-query";

// Import state slices
import createFactSlice from "./slices/factSlice";
import createSkillSlice from "./slices/skillSlice";
import createTechnologySlice from "./slices/technologySlice";
import createExperienceSlice from "./slices/experiencesSlice";
import createProjectSlice from "./slices/projectSlice";
import createThemeSlice from "./slices/themeSlice";
import createViewportSlice from "./slices/viewportSlice";
import createSlideOutSlice from "./slices/slideOutSlice";
import createSceneTransitionSlice from "./slices/sceneTransitionSlice";
import createArticleSlice from "./slices/articleSlice";

/**
 * QueryClient configuration for React Query
 * @type {QueryClient}
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 60 * 1000, // 10 minutes
    },
  },
});

/**
 * Creates the main Zustand store by combining all slices
 * @type {Function}
 */
const useStore = create(
  devtools(
    persist(
      (set, get) => ({
        ...createArticleSlice(set, get),
        ...createFactSlice(set, get),
        ...createSkillSlice(set, get),
        ...createTechnologySlice(set, get),
        ...createExperienceSlice(set, get),
        ...createProjectSlice(set, get),
        ...createThemeSlice(set, get),
        ...createViewportSlice(set, get),
        ...createSlideOutSlice(set, get),
        ...createSceneTransitionSlice(set, get),
      }),
      {
        name: "root-storage",
        partialize: (state) => ({
          theme: state.theme,
          themeName: state.themeName,
          currentScene: state.currentScene,
        }),
      }
    )
  )
);

export default useStore;

// Usage example:
// import useStore from './store';
//
// function MyComponent() {
//   const { articles, fetchArticles, theme, toggleTheme } = useStore();
//
//   useEffect(() => {
//     fetchArticles();
//   }, []);
//
//   return (
//     <div>
//       <button onClick={toggleTheme}>Switch Theme</button>
//       {articles.map(article => (
//         <ArticleCard key={article.id} article={article} />
//       ))}
//     </div>
//   );
// }
