import React, { useMemo } from "react";
import useStore from "../stores/store";

function Home() {
  const { theme } = useStore();

  return <></>;
}

export default Home;
